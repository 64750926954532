<template>
    <div v-if="tables">
        <b-card class="my-1 d-flex align-center" :title="$t('config.tables.tables')">
            <Tables :businessUnitRoomId="businessUnitRoomId" v-on:addTableToRoom="addTableToRoom" v-on:itemEdited="loadData" @back="$emit('back')"/>
        </b-card>
        <b-card>
            <div v-if="$hasPermission($permissions.ConfigurationWrite)" class="text-right">
                <b-button variant="primary" @click="saveTables">{{$t('general.save')}}</b-button>
            </div>
            <!--  <div class="p-0 m-0" style="position: relative;">
                <div id="restaurant" class="background mt-2">
                    <canvas id="restaurantTables" ref="canvas" class="h-full">
                    </canvas>
                </div>
            </div>-->
            <div class="p-0 m-0" >
                <div id="restaurant" class="background mt-2">
                    <canvas id="restaurantTables" ref="canvas" class="h-full"></canvas>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
    import Tables from '@/views/WorkPremises/Tables'
    import {BCard, BButton} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {Shapes} from '@/libs/enums/ViewRooms'

    export default {
        props:{
            workPremiseId: {
                type: String,
                required:false,
                default: ''
            }
        },
        components: {Tables, BCard, BButton},
        data() {
            return {
                businessUnitRoomId: '',
                currentSelected: null,
                canvas: null,
                ctx: null,
                tableSelected: false,
                tables: [],
                draggingResizer: -1,
                draggingImage: false,
                startResizing: false,
                beforeResizeY: 0,
                beforeResizeX: 0,
                drawLine: false,
                drawLineCordinateX: [],
                drawLineX: [],
                drawLineY: [],
                drawLineCordinateY: [],
                drawLineXSize: 0,
                drawLineYSize: 0,
                drawLineRSize:0
            }
        },
        methods:{
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get(`/api/client/v1/work_premises/${this.businessUnitRoomId}/tables`)
                    this.tables = response.data ?? []
                    await this.drawAll()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async saveTables() {
                try {
                    await this.$http.put(`/api/client/v1/work_premises/${this.businessUnitRoomId}/tables`, this.tables)
                    this.$printSuccess(this.$t('print.success.edit'))
                    await this.loadData()
                    this.$emit('reload')
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            addTableToRoom(table) {
                if (table.x !== 0 && table.x !== 0) {
                    this.$printWarning(this.$t('print.warning.table_already_been_added'))
                    return
                }

                const index = this.tables.findIndex(x => x.id === table.id)
                if (index > -1) {
                    if (this.tables[index].shape_type === 1)  {
                        this.tables[index].x = (12 / this.canvas.width) * 10000
                        this.tables[index].y = (12 / this.canvas.height) * 10000
                        this.tables[index].x_size = (200 / this.canvas.width) * 10000
                        this.tables[index].y_size = (150 / this.canvas.height) * 10000

                        //Mora biti, da se skrije gumb Dodaj
                        table.x = (10 / this.canvas.width) * 10000
                        table.y = (10 / this.canvas.height) * 10000
                    } else {
                        this.tables[index].x = (110 / this.canvas.width) * 10000
                        this.tables[index].y = (130 / this.canvas.width) * 10000
                        this.tables[index].r = (120 / this.canvas.width) * 10000

                        //Mora biti, da se skrije gumb Dodaj
                        table.x = (100 / this.canvas.width) * 10000
                        table.y = (120 / this.canvas.width) * 10000
                    }


                    if (this.checkForColision(this.tables[index].x, this.tables[index].y, this.tables[index].x_size, this.tables[index].y_size, this.tables[index].x, this.tables[index].y)) {
                        this.currentSelected = this.tables[index]
                        this.drawAll()
                        this.drawRect(this.tables[index], 1, true)
                        this.tableSelected = true
                    }
                }
                this.drawAll()
            },
            checkForColision(x, y, x_size, y_size, x1, y1) {

                const left = x, right = x + x_size
                const top = y, bottom = y + y_size
                return right >= x1 && left <= x1 && bottom >= y1 && top <= y1
            },
            checkForColisionCircle(x, y, r, x1, y1) {

                const distX = x - x1
                const distY = y - y1
                const distance = Math.sqrt((distX * distX) + (distY * distY))

                return distance <= r
            },
            drawRect(item, lineWidth, white) {
                //Sama miza
                this.ctx.beginPath()
                if (!white) this.ctx.fillStyle = '#656565'
                else this.ctx.fillStyle = '#505050'

                this.ctx.strokeStyle = '#454545'

                const x = (this.canvas.width * (item.x / 10000))
                const y = (this.canvas.height * (item.y / 10000))
                const x_size = (this.canvas.width * (item.x_size / 10000))
                const y_size = (this.canvas.height * (item.y_size / 10000))

                this.ctx.rect(x, y, x_size, y_size)
                this.ctx.fill()

                //Sredinska stevilka mize
                const fontSize = 30

                this.ctx.font = `${fontSize }px arial`
                const xText = x + (x_size / 2)
                const yText = y + (y_size / 2) + 10
                this.ctx.lineWidth = lineWidth

                this.ctx.fillStyle = 'black'
                this.ctx.textAlign = 'center'
                this.ctx.fillText(item.name, xText, yText)
                this.ctx.stroke()

                //Napis skrita miza
                if (item.hidden) {
                    const fontSizeHidden = 12
                    const opacity = 0.5  // Opacity value between 0 (fully transparent) and 1 (fully opaque)

                    this.ctx.globalAlpha = opacity
                    this.ctx.font = `${fontSizeHidden}px arial `
                    const xTextHidden = x + (x_size / 2)
                    const yTextHidden = y + (y_size / 2) + 25
                    this.ctx.lineWidth = lineWidth

                    this.ctx.fillStyle = 'black'
                    this.ctx.textAlign = 'center'
                    this.ctx.fillText(this.$t('table_fields.hidden_table'), xTextHidden, yTextHidden)
                    this.ctx.stroke()
                    this.ctx.globalAlpha = 1
                }

                if (white) {
                    this.ctx.fillStyle = '#656565'
                    this.drawDragAnchor(x, y)
                    this.drawDragAnchor(x_size + x, y)
                    this.drawDragAnchor(x, y_size + y)
                    this.drawDragAnchor(x_size + x, y_size + y)
                }
            },
            drawCircle(item, lineWidth, white = false) {
                const x = (this.canvas.width * (item.x / 10000))
                const y = (this.canvas.height * (item.y / 10000))
                const r = (this.canvas.height * (item.r / 10000))

                this.ctx.beginPath()

                if (!white) this.ctx.fillStyle = '#656565'
                else this.ctx.fillStyle = '#505050'

                this.ctx.strokeStyle = '#454545'
                this.ctx.lineWidth = lineWidth

                this.ctx.arc(x, y, r, 0, 2 * Math.PI)

                this.ctx.fill()

                this.ctx.textAlign = 'center'

                const fontSize = 30

                this.ctx.font = `${fontSize }px arial`
                this.ctx.fillStyle = 'black'
                this.ctx.fillText(item.name, x + 3, y + 10)
                this.ctx.stroke()

                if (item.hidden) {
                    const fontSizeHidden = 12
                    const opacity = 0.5  // Opacity value between 0 (fully transparent) and 1 (fully opaque)

                    this.ctx.globalAlpha = opacity
                    this.ctx.font = `${fontSizeHidden}px arial`

                    this.ctx.fillStyle = 'black'
                    this.ctx.fillText(this.$t('table_fields.hidden_table'), x + 3, y + 25)
                    this.ctx.stroke()
                    this.ctx.globalAlpha = 1
                }


                if (white) {
                    this.ctx.fillStyle = '#656565'
                    this.drawDragAnchor(x + r, y)
                }
            },
            drawAll() {
                this.ctx.beginPath()
                this.ctx.fillStyle = '#888888'
                this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

                for (const item of this.tables) {

                    let selected = false
                    if (this.currentSelected && this.currentSelected.id === item.id) {
                        selected = true
                    }

                    if (item.shape_type === Shapes.Rectangle && item.x !== 0 && item.y !== 0) this.drawRect(item, 1, selected)
                    else if (item.shape_type === Shapes.Circle && item.x !== 0 && item.y !== 0) this.drawCircle(item, 1, selected)
                }

                this.drawLineFunc()
            },
            drawLineFunc() {
                this.ctx.beginPath()
                this.ctx.fillStyle = '#e89717'


                if (this.drawLineX.length > 0) { //Horizontalno
                    for (const xItem of this.drawLineCordinateX) {
                        const x = xItem
                        const y = 0
                        const x1 = xItem
                        const y1 = this.canvas.height

                        this.ctx.strokeStyle = '#e89717'
                        this.ctx.lineWidth = 1
                        this.ctx.setLineDash([10, 5])
                        this.ctx.moveTo(x, y)
                        this.ctx.lineTo(x1, y1)
                        this.ctx.stroke()
                        this.ctx.setLineDash([])
                    }
                }

                if (this.drawLineY.length > 0) { //Vertikalno
                    for (const yItem of this.drawLineCordinateY) {
                        const x = 0
                        const y = yItem
                        const x1 = this.canvas.width
                        const y1 = yItem

                        this.ctx.strokeStyle = '#e89717'
                        this.ctx.lineWidth = 1
                        this.ctx.setLineDash([10, 5])
                        this.ctx.moveTo(x, y)
                        this.ctx.lineTo(x1, y1)
                        this.ctx.stroke()
                        this.ctx.setLineDash([])
                    }
                }
            },
            click(e) {
                let ok = false

                for (const item of this.tables) {
                    const x = this.canvas.width * (item.x / 10000)
                    const y = this.canvas.height * (item.y / 10000)

                    if (item.x !== 0 && item.y !== 0) {
                        if (item.shape_type === Shapes.Rectangle) {
                            this.drawAll()
                            const x_size = this.canvas.width * (item.x_size / 10000)
                            const y_size = this.canvas.height * (item.y_size / 10000)

                            if (this.checkForColision(x, y, x_size, y_size, e.layerX, e.layerY)) {
                                this.currentSelected = item
                                this.drawRect(item, 1, true)
                                this.tableSelected = true
                                ok = true
                                break
                            }
                        } else if (item.shape_type === Shapes.Circle) {
                            const r = this.canvas.height * (item.r / 10000)

                            if (this.checkForColisionCircle(x, y, r, e.layerX, e.layerY)) {
                                this.currentSelected = item
                                this.drawCircle(item, 1, true)
                                this.tableSelected = true
                                ok = true
                                break
                            }
                        }
                    }
                }
                if (!ok) {
                    this.tableSelected = false
                    this.currentSelected = null
                }

                if (this.tableSelected === false) {
                    this.drawAll()
                }
            },
            anchorHitTest(beforeMoveX, beforeMoveY, item) {
                let dx = 0
                let dy = 0

                const x = (this.canvas.width * (item.x / 10000))
                const y = (this.canvas.height * (item.y / 10000))

                beforeMoveX = (beforeMoveX) - x
                beforeMoveY = (beforeMoveY) - y

                const allowedDiff = 8

                if (item.shape_type === Shapes.Rectangle) {
                    const x_size = (this.canvas.width * (item.x_size / 10000))
                    const y_size = (this.canvas.height * (item.y_size / 10000))

                    // top-left
                    dx = beforeMoveX
                    dy = beforeMoveY

                    if (Math.abs(dx) <= allowedDiff && Math.abs(dy) <= allowedDiff) {
                        return (0)
                    }

                    // top-right
                    dx = beforeMoveX - (x_size)
                    dy = beforeMoveY
                    if (Math.abs(dx) <= allowedDiff && Math.abs(dy) <= allowedDiff) {
                        return (1)
                    }
                    // bottom-right
                    dx = beforeMoveX - (x_size)
                    dy = beforeMoveY - (y_size)
                    if (Math.abs(dx) <= allowedDiff && Math.abs(dy) <= allowedDiff) {
                        return (2)
                    }
                    // bottom-left
                    dx = beforeMoveX
                    dy = beforeMoveY - (y_size)


                    if (Math.abs(dx) <= allowedDiff && Math.abs(dy) <= allowedDiff) {
                        return (3)
                    }
                } else {
                    const r = this.canvas.height * (item.r / 10000)

                    dx = beforeMoveX - r

                    if (Math.abs(dx) <= allowedDiff) {
                        return (4)
                    }
                }
                return (-1)
            },
            getBoundingRect(item) {

                if (item.shape_type === Shapes.Rectangle) {
                    return {
                        x: item.x,
                        y: item.y,
                        x_size: item.x_size,
                        y_size:item.y_size,
                        name: item.name
                    }
                } else if (item.shape_type === Shapes.Circle) {

                    const xPercentage = this.canvas.height / this.canvas.width

                    return {
                        x: item.x - (item.r * xPercentage),
                        y: item.y - item.r,
                        x_size: (item.r * xPercentage) * 2,
                        y_size: item.r * 2,
                        name: item.name
                    }
                }

                return null
            },
            checkIfObjectAreAlignedX(item1, item2) {
                const allowedDiffrence = 100 //Dovoljeno da je 100px zamaknjen da ga bo samo nastavilo v isto linijo

                const hits = []

                if (Math.abs(item1.x - item2.x) < allowedDiffrence) {
                    hits.push(1)
                }

                if (Math.abs(item1.x + item1.x_size - item2.x) < allowedDiffrence) {
                    hits.push(2)
                }

                if (Math.abs(item1.x - (item2.x_size + item2.x)) < allowedDiffrence) {
                    hits.push(3)
                }

                if (Math.abs((item1.x + item1.x_size) - (item2.x_size + item2.x)) < allowedDiffrence) {
                    hits.push(4)
                }


                return hits
            },
            checkIfObjectAreAlignedY(item1, item2) {
                const allowedDiffrence = 100 //Dovoljeno da je 100px zamaknjen da ga bo samo nastavilo v isto linijo

                const hits = []

                if (Math.abs(item1.y - item2.y) < allowedDiffrence) {
                    hits.push(1)
                }

                if (Math.abs(item1.y + item1.y_size - item2.y) < allowedDiffrence) {
                    hits.push(2)
                }

                if (Math.abs(item1.y - (item2.y + item2.y_size)) < allowedDiffrence) {
                    hits.push(3)
                }

                if (Math.abs(item1.y + item1.y_size - (item2.y + item2.y_size)) < allowedDiffrence) {
                    hits.push(4)
                }

                return hits
            },
            checkIfSideIsInLineWithOtherTable(x, y, x_size_in, y_size) {

                const arrayOfHits = []

                this.drawLineX = []
                this.drawLineY = []
                this.drawLineCordinateX = []
                this.drawLineCordinateY = []

                const boundingRect = {
                    x,
                    y,
                    x_size: x_size_in,
                    y_size
                }

                for (const item of this.tables) {
                    if (item.id === this.currentSelected.id) {
                        continue
                    }

                    const itemBoundingRect = this.getBoundingRect(item)
                    if (!itemBoundingRect) {
                        return
                    }

                    if (!arrayOfHits.includes(1) && !arrayOfHits.includes(3)) {
                        let x_new = -1
                        let found1 = false


                        const touching = this.checkIfObjectAreAlignedX(itemBoundingRect, boundingRect)

                        if (touching.includes(1))  {
                            x_new = (this.canvas.width * (itemBoundingRect.x / 10000))
                            found1 = true
                            this.drawLineXSize = (this.canvas.width * ((this.currentSelected.x - itemBoundingRect.x) + this.currentSelected.x_size) / 10000)
                        } else  if (touching.includes(2)) {
                            x_new = (this.canvas.width * ((itemBoundingRect.x + itemBoundingRect.x_size) / 10000))
                            found1 = true
                            this.drawLineXSize = (this.canvas.width * ((this.currentSelected.x - (itemBoundingRect.x + itemBoundingRect.x_size)) + this.currentSelected.x_size) / 10000)
                        }

                        if (found1) {
                            this.drawLineX.push(1)
                            this.drawLineCordinateX.push(x_new)
                            this.drawLineFunc()
                            arrayOfHits.push(1)
                        }

                        let found2 = false
                        if (touching.includes(3)) {
                            x_new = (this.canvas.width * ((itemBoundingRect.x) / 10000))
                            found2 = true
                            this.drawLineXSize = (this.canvas.width * (itemBoundingRect.x - this.currentSelected.x) / 10000)
                        } else  if (touching.includes(4)) {
                            x_new = (this.canvas.width * ((itemBoundingRect.x + itemBoundingRect.x_size) / 10000))
                            found2 = true
                            this.drawLineXSize = (this.canvas.width * (itemBoundingRect.x + itemBoundingRect.x_size - this.currentSelected.x) / 10000)
                        }

                        if (found2) {
                            this.drawLineX.push(3)
                            this.drawLineCordinateX.push(x_new)
                            this.drawLineFunc()
                            arrayOfHits.push(3)
                        }
                    }

                    if (!arrayOfHits.includes(2) && !arrayOfHits.includes(4)) {
                        let y_new = -1
                        let found1 = false

                        const touching = this.checkIfObjectAreAlignedY(itemBoundingRect, boundingRect)

                        if (touching.includes(1))  {
                            y_new = (this.canvas.height * (itemBoundingRect.y / 10000))
                            found1 = true
                            this.drawLineYSize = (this.canvas.height * (this.currentSelected.y - itemBoundingRect.y + this.currentSelected.y_size) / 10000)
                        } else  if (touching.includes(2)) {
                            y_new = (this.canvas.height * ((itemBoundingRect.y + itemBoundingRect.y_size) / 10000))
                            found1 = true

                            this.drawLineYSize = (this.canvas.height * (this.currentSelected.y - (itemBoundingRect.y + itemBoundingRect.y_size) + this.currentSelected.y_size) / 10000)
                            this.drawLineRSize = (this.canvas.width * ((this.currentSelected.x - (itemBoundingRect.x + itemBoundingRect.x_size)) + this.currentSelected.x_size) / 10000)

                        }

                        if (found1) {

                            this.drawLineY.push(2)
                            this.drawLineCordinateY.push(y_new)
                            this.drawLineFunc()
                            arrayOfHits.push(2)
                        }

                        let found2 = false

                        if (touching.includes(3))  {
                            y_new = (this.canvas.height * (itemBoundingRect.y / 10000))
                            found2 = true
                            this.drawLineYSize = (this.canvas.height * (itemBoundingRect.y - this.currentSelected.y) / 10000)
                        } else  if (touching.includes(4)) {
                            y_new = (this.canvas.height * ((itemBoundingRect.y + itemBoundingRect.y_size) / 10000))
                            found2 = true
                            this.drawLineYSize = (this.canvas.height * (itemBoundingRect.y + itemBoundingRect.y_size - this.currentSelected.y) / 10000)
                        }

                        if (found2) {
                            this.drawLineY.push(4)
                            this.drawLineCordinateY.push(y_new)
                            this.drawLineFunc()
                            arrayOfHits.push(4)
                        }
                    }
                }

                return arrayOfHits
            },
            async enableElementDrag(e) {

                await this.click(e)

                if (this.currentSelected) {
                    const newItem = this.getBoundingRect(this.currentSelected)

                    const x = this.canvas.width * (newItem.x / 10000)
                    const y = this.canvas.height * (newItem.y / 10000)

                    if (this.currentSelected.x === 0 && this.currentSelected.y === 0) return

                    const beforeMoveX = e.layerX
                    const beforeMoveY = e.layerY

                    this.draggingResizer = this.anchorHitTest(beforeMoveX, beforeMoveY, this.currentSelected)
                    this.draggingImage = this.draggingResizer < 0

                    if (this.draggingImage) {
                        let lastX = 0, lastY = 0

                        const x_size = this.canvas.width * (newItem.x_size / 10000)
                        const y_size = this.canvas.height * (newItem.y_size / 10000)

                        if (this.checkForColision(x, y, x_size, y_size, e.layerX, e.layerY)) {

                            document.onmousemove = (e) => {
                                const diffX = e.layerX - beforeMoveX
                                const diffY = e.layerY - beforeMoveY

                                lastX = x + diffX
                                lastY = y + diffY

                                const arrayOfHits = this.checkIfSideIsInLineWithOtherTable(((lastX / this.canvas.width) * 10000), ((lastY / this.canvas.height) * 10000), newItem.x_size, newItem.y_size, this.currentSelected.r, this.currentSelected.shape_type)

                                if (arrayOfHits.length <= 0) {
                                    newItem.x = ((lastX / this.canvas.width) * 10000)
                                    newItem.y = ((lastY / this.canvas.height) * 10000)
                                } else {

                                    if (arrayOfHits.includes(1)) {
                                        const index = this.drawLineX.findIndex(ele => ele === 1)
                                        newItem.x = ((this.drawLineCordinateX[index] / this.canvas.width) * 10000)
                                    } else if (arrayOfHits.includes(3)) {
                                        const index = this.drawLineX.findIndex(ele => ele === 3)
                                        newItem.x = (((this.drawLineCordinateX[index] - x_size) / this.canvas.width) * 10000)
                                    }

                                    if (arrayOfHits.includes(2)) {
                                        const index = this.drawLineY.findIndex(ele => ele === 2)
                                        newItem.y = ((this.drawLineCordinateY[index] / this.canvas.height) * 10000)
                                    } else if (arrayOfHits.includes(4)) {
                                        const index = this.drawLineY.findIndex(ele => ele === 4)
                                        newItem.y = (((this.drawLineCordinateY[index] - y_size) / this.canvas.height) * 10000)
                                    }

                                    if (this.drawLineY.length === 0 && (!arrayOfHits.includes(4) || !arrayOfHits.includes(2))) {
                                        newItem.y = ((lastY / this.canvas.height) * 10000)
                                    }

                                    if (this.drawLineX.length === 0 && (!arrayOfHits.includes(1) || !arrayOfHits.includes(3))) {
                                        newItem.x = ((lastX / this.canvas.width) * 10000)
                                    }

                                }

                                this.drawLineFunc()
                                this.setCurrentSelected(newItem, false)
                                this.drawAll()
                            }
                        }
                    }
                } else {
                    this.drawAll()
                }
            },
            setCurrentSelected(item, resizing) {

                if (this.currentSelected.shape_type === Shapes.Rectangle) {

                    this.currentSelected.x = item.x
                    this.currentSelected.y =  item.y
                    this.currentSelected.x_size = item.x_size
                    this.currentSelected.y_size = item.y_size

                } else if (this.currentSelected.shape_type === Shapes.Circle) {
                    // 
                    if (resizing) {
                        this.currentSelected.r = (item.x_size) / 2
                    } else {
                        this.currentSelected.x = item.x + (item.x_size / 2)
                        this.currentSelected.y =  item.y + (item.y_size / 2)
                    }

                }
            },
            drawDragAnchor(x, y) {
                this.ctx.beginPath()
                this.ctx.arc(x, y, 8, 0, Math.PI * 2, false)
                this.ctx.closePath()
                this.ctx.fill()
            },
            handleMouseMove(e) {

                const mouseX = e.layerX
                const mouseY = e.layerY

                if (this.draggingResizer > -1) {

                    const newItemTest = this.getBoundingRect(this.currentSelected)

                    const x = this.canvas.width * (newItemTest.x / 10000)
                    const y = this.canvas.height * (newItemTest.y / 10000)
                    const x_size = this.canvas.width * (newItemTest.x_size / 10000)
                    const y_size = this.canvas.height * (newItemTest.y_size / 10000)

                    let newX = x
                    let newY = y
                    let newWidth = x_size
                    let newHeight = y_size

                    if (!this.startResizing)  {
                        this.beforeResizeY = y
                        this.beforeResizeX = x
                    }
                    this.startResizing = true

                    if (this.currentSelected.shape_type === Shapes.Circle) {
                        const r = this.canvas.height * (this.currentSelected.r / 10000) / 100
                        const x = this.canvas.width * (this.currentSelected.x / 10000)

                        let newR = r

                        switch (this.draggingResizer) {
                        case 4:
                            newR = (mouseX  - x)
                            break
                        }

                        const minSize = 65

                        if (newR < minSize) {
                            return
                        }

                        if (this.checkForColisionCircle(mouseX, mouseY, newR, e.layerX, e.layerY)) {
                            this.currentSelected.r = (newR / this.canvas.height) * 10000
                            this.drawAll()
                        }

                        return
                    }


                    // resize the image
                    switch (this.draggingResizer) {
                    case 0:
                        //top-left
                        newX = mouseX
                        newWidth = (x_size + x) - mouseX
                        newY = mouseY
                        newHeight = (y_size + y) - mouseY
                        break
                    case 1:
                        //top-right
                        newY = mouseY
                        newWidth = mouseX - x
                        newHeight = (y_size + y) - mouseY
                        break
                    case 2:
                        //bottom-right
                        newWidth = mouseX - x
                        newHeight = mouseY - y
                        break
                    case 3:
                        //bottom-left
                        newX = mouseX
                        newWidth = (x_size + x) - mouseX
                        newHeight = mouseY - y
                        break
                    case 4:
                        //Circle middle
                        newWidth = mouseX - x
                        newHeight = mouseX - x
                        newX = x - ((mouseX - (x + x_size)) / 2)
                        newY = y - ((mouseX - (x + x_size)) / 2)
                        break
                    }

                    newX = newX.toFixed(0)
                    newY = newY.toFixed(0)
                    newWidth = newWidth.toFixed(0)
                    newHeight = newHeight.toFixed(0)

                    const minSize = 100

                    if (newWidth <= minSize) {
                        newWidth = minSize
                        newX = this.beforeResizeX
                    }

                    if (newHeight <= minSize) {
                        newHeight = minSize
                        newY = this.beforeResizeY
                    }

                    if (this.checkForColision(newX, newY, newWidth, newHeight, e.layerX, e.layerY)) {
                        const arrayOfHits = this.checkIfSideIsInLineWithOtherTable(((newX / this.canvas.width) * 10000), ((newY / this.canvas.height) * 10000), (newWidth / this.canvas.width) * 10000, (newHeight / this.canvas.height) * 10000)

                        const newItem = this.getBoundingRect(this.currentSelected)

                        if (arrayOfHits.length === 0) {
                            newItem.x = (newX / this.canvas.width) * 10000
                            newItem.y = (newY / this.canvas.height) * 10000
                            newItem.x_size = (newWidth / this.canvas.width) * 10000
                            newItem.y_size = (newHeight / this.canvas.height) * 10000
                            newItem.x1 = newItem.x +  newItem.x_size
                            newItem.y1 = newItem.y +  newItem.y_size
                        } else {

                            if (arrayOfHits.includes(1)) {
                                const index = this.drawLineX.findIndex(ele => ele === 1)
                                newItem.x = (this.drawLineCordinateX[index] / this.canvas.width) * 10000
                                newItem.x_size = (this.drawLineXSize / this.canvas.width) * 10000
                            } else if (arrayOfHits.includes(3)) {
                                newItem.x_size = (this.drawLineXSize / this.canvas.width) * 10000
                            }

                            if (arrayOfHits.includes(2)) {
                                const index = this.drawLineY.findIndex(ele => ele === 2)
                                newItem.y = ((this.drawLineCordinateY[index] / this.canvas.height) * 10000)
                                newItem.y_size = (this.drawLineYSize / this.canvas.height) * 10000
                            } else if (arrayOfHits.includes(4)) {
                                newItem.y_size = (this.drawLineYSize / this.canvas.height) * 10000
                            }


                            if (this.currentSelected.shape_type !== Shapes.Circle) {

                                if ((this.draggingResizer === 0 || this.draggingResizer === 3) && arrayOfHits.includes(3)) {
                                    newItem.x = (newX / this.canvas.width) * 10000
                                } else if ((this.draggingResizer === 1 || this.draggingResizer === 2) && arrayOfHits.includes(1)) {

                                    if (!arrayOfHits.includes(3)) {
                                        newItem.x_size = (newWidth / this.canvas.width) * 10000
                                    }

                                    if (!arrayOfHits.includes(4) && !arrayOfHits.includes(2)) {
                                        newItem.y_size = (newHeight / this.canvas.height) * 10000
                                    }
                                } else if ((this.draggingResizer === 3 || this.draggingResizer === 2) && arrayOfHits.includes(2)) {
                                    if (!arrayOfHits.includes(4)) {
                                        newItem.y_size = (newHeight / this.canvas.height) * 10000
                                    }

                                    if (!arrayOfHits.includes(3) && !arrayOfHits.includes(1)) {
                                        newItem.x_size = (newWidth / this.canvas.width) * 10000
                                    }

                                } else if ((this.draggingResizer === 1 || this.draggingResizer === 0) && arrayOfHits.includes(4)) {
                                    newItem.y = (newY / this.canvas.height) * 10000
                                    newItem.y_size = (newHeight / this.canvas.height) * 10000

                                    if (!arrayOfHits.includes(3)) {
                                        newItem.x_size = (newWidth / this.canvas.width) * 10000
                                        newItem.x = (newX / this.canvas.width) * 10000
                                    }

                                }

                                if ((arrayOfHits.includes(4) || arrayOfHits.includes(2)) && (!arrayOfHits.includes(1) && !arrayOfHits.includes(3))) {
                                    newItem.x = (newX / this.canvas.width) * 10000
                                    newItem.x_size = (newWidth / this.canvas.width) * 10000
                                } else if ((arrayOfHits.includes(1) || arrayOfHits.includes(3)) && (!arrayOfHits.includes(2) && !arrayOfHits.includes(4))) {
                                    newItem.y = (newY / this.canvas.height) * 10000
                                    newItem.y_size = (newHeight / this.canvas.height) * 10000
                                }
                            }

                        }

                        this.setCurrentSelected(newItem, true)
                        this.drawAll()
                        this.drawLineFunc()
                    }

                } else {
                    this.startResizing = false
                }
            },
            handleMouseUp() {
                this.draggingResizer = -1
                document.onmouseup = null
                document.onmousemove = null
                this.startResizing = false
                this.drawLineX = []
                this.drawLineCordinateX = []
                this.drawLineY = []
                this.drawLineCordinateY = []
                this.drawAll()

                if (this.currentSelected) {
                    const x = (this.canvas.width * (this.currentSelected.x / 10000))
                    const y = (this.canvas.height * (this.currentSelected.y / 10000))
                    const x_size = (this.canvas.width * (this.currentSelected.x_size / 10000))
                    const y_size = (this.canvas.height * (this.currentSelected.y_size / 10000))
                    this.ctx.fillStyle = '#656565'

                    if (this.currentSelected.shape_type === Shapes.Rectangle) {
                        this.drawDragAnchor(x, y)
                        this.drawDragAnchor(x_size + x, y)
                        this.drawDragAnchor(x, y_size + y)
                        this.drawDragAnchor(x_size + x, y_size + y)
                    }
                }
            },
            handleMouseOut() {
                this.handleMouseUp()
            },
            resize() {
                const parent = this.canvas.parentNode
                const styles = getComputedStyle(parent)
                this.ctx.canvas.width  = parseInt(styles.getPropertyValue('width'), 10) + 5
                this.ctx.canvas.height = parseInt(styles.getPropertyValue('height'), 10)
                this.drawAll()
            }
        },
        beforeMount() {

            this.businessUnitRoomId = this.workPremiseId


            this.loadData()
        },
        mounted() {
            this.canvas = document.getElementById('restaurantTables')
            this.ctx = this.canvas.getContext('2d')

            const parent = this.canvas.parentNode
            const styles = getComputedStyle(parent)
            this.ctx.canvas.width  = parseInt(styles.getPropertyValue('width'), 10)
            this.ctx.canvas.height = parseInt(styles.getPropertyValue('height'), 10)

            this.drawAll()

            window.addEventListener('resize', this.resize)

            if (this.$hasPermission(this.$permissions.ConfigurationWrite)) {
                this.canvas.addEventListener('mousedown', this.enableElementDrag)
                this.canvas.addEventListener('mouseup', this.handleMouseUp)
                this.canvas.addEventListener('mousemove', this.handleMouseMove)
                this.canvas.addEventListener('mouseout', this.handleMouseOut)
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resize)

            if (this.$hasPermission(this.$permissions.ConfigurationWrite)) {
                this.canvas.removeEventListener('mousedown', this.enableElementDrag)
                this.canvas.removeEventListener('mouseup', this.handleMouseUp)
                this.canvas.removeEventListener('mousemove', this.handleMouseMove)
                this.canvas.removeEventListener('mouseout', this.handleMouseOut)
            }
        }
    }
</script>

<style scoped>
.background{
  background: white !important;
  height: 100vh;
}
#restaurantTables {
  /*cursor: pointer;*/
}
#restaurantTables:active {
  cursor: move;
}
</style>
<style>
.resize-handle-ne, .resize-handle-ne, .resize-handle-se, .resize-handle-nw, .resize-handle-sw {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background: rgba(222,60,80,.9);
  z-index: 999;
}
.resize-handle-nw {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}
.resize-handle-sw {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}
.resize-handle-ne {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}
.resize-handle-se {
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
}
</style>