<template>
    <b-modal :title="$t('keys.permissions.permissions')" size="xl" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                        <b-form-input autofocus v-model="addObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('menu.subcategory.permissions')">
                    <b-form-checkbox-group
                        v-model="addObject.permissions"
                    >
                        <div v-for="permission in permissionOptions" :key="'Pravica_' + permission.value">
                            <b-form-checkbox :class="[{'indentForPermissions': permission.value > 200}, {'marginBottomPermissions': permission.value === 200}]" :value="permission.value" :disabled="(permission.value === 101 && !addObject.permissions.includes(100)) || ((permission.value > 200 && permission.value <= 209) && !addObject.permissions.includes(200))" @change="checkIfPermissionsAreValid">
                                {{ permission.name }}
                                <span style="font-size: 11px;" class="text-warning" v-if="permission.value === 101 && !addObject.permissions.includes(100)">
                                    {{ $t('keys.permissions.issued_bills_needed') }}
                                </span>
                                <span style="font-size: 11px;" class="text-warning" v-if="(permission.value > 200 && permission.value <= 209) && !addObject.permissions.includes(200)">
                                    {{ $t('keys.permissions.reports_needed') }}

                                </span>
                            </b-form-checkbox>
                            <b-form-group class="my-1" :label="$t('permissions.payment_types')"  v-if="addObject.permissions.includes(110) && permission.value === 110">
                                <validation-provider #default="{ errors }" :name="$t('permissions.payment_types')" rules="required">
                                    <v-select v-model="addObject.enabled_payment_types" multiple appendToBody :options="payment_types" label="name" :reduce="ele => ele.id"/>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>
                    </b-form-checkbox-group>
                </b-form-group>

                <b-form-group :label="$t('keys.permissions.subcategories')"  v-if="addObject.permissions.includes(209)">
                    <validation-provider #default="{ errors }" :name="$t('general.category')" rules="required">
                        <v-select v-model="addObject.enabled_categories" multiple appendToBody :options="subcategories" label="name" :reduce="ele => ele.id"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckboxGroup, BFormCheckbox} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import * as Sentry from '@sentry/vue'
    import vSelect from 'vue-select'
    import {getPermissions} from '@/libs/constants/employees'


    export default {
        components: {
            vSelect,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormCheckboxGroup,
            BFormCheckbox,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                selectedCategories: [],
                subcategories: [],
                payment_types: [],
                modalActive: false,
                addObject: {
                    name: '',
                    permissions: [],
                    enabled_categories: [],
                    enabled_payment_types: []
                },
                required,
                editing: false
            }
        },
        computed: {
            permissionOptions() {
                return getPermissions()
            }
        },
        methods: {
            async loadData() {
                try {
                    const response1 = await this.$http.get('/api/client/v1/payment_types/')
                    this.payment_types = response1.data ?? []
                    const response2 = await this.$http.get('/api/client/v1/product_subcategories/')
                    this.subcategories = response2.data ?? []
                } catch (err) {
                    Sentry.captureMessage(err)
                }
            },
            checkIfPermissionsAreValid() {
                if (this.addObject.permissions.includes(101) && !this.addObject.permissions.includes(100)) {
                    this.addObject.permissions = this.addObject.permissions.filter(ele => ele !== 101)
                }

                if (!this.addObject.permissions.includes(200)) {
                    this.addObject.permissions = this.addObject.permissions.filter(ele => ele < 200)
                    this.addObject.enabled_categories = []
                }

                if (!this.addObject.permissions.includes(209)) {
                    this.addObject.enabled_categories = []
                }

                if (!this.addObject.permissions.includes(110)) {
                    this.addObject.enabled_payment_types = []
                }

            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        permissions: [],
                        enabled_categories: [],
                        enabled_payment_types: []
                    }
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    const response = await this.$http.post('/api/client/v1/employee_permission_groups/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        permissions: [],
                        enabled_categories: [],
                        enabled_payment_types: []

                    }
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'Pravica za storno mora obvezno imeti tudi pravico za izdane račune')) {
                        this.$printWarning(this.$t('print.warning.reversaL_permission_needs_issued_bills'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_add'))
                    }
                }
            },
            async edit() {
                try {
                    await this.$http.put(`/api/client/v1/employee_permission_groups/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        permissions: [],
                        enabled_categories: [],
                        enabled_payment_types: []
                    }
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'Pravica za storno mora obvezno imeti tudi pravico za izdane račune')) {
                        this.$printWarning(this.$t('print.warning.reversaL_permission_needs_issued_bills'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_add'))
                    }
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>
 .indentForPermissions{
   margin-left: 20px;
 }

 .marginBottomPermissions{
   margin-bottom: 8px;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>