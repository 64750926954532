<template>
    <b-overlay :show="showLoader">
        <b-row>
            <b-col class="text-left">
                <b-button variant="primary" @click="$emit('back')">
                    {{ $t('general.back_to_rooms') }}
                </b-button>
            </b-col>

            <b-col class="text-right">
                <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" variant="primary" @click="$refs.editModal.open()">
                    <feather-icon icon="EditIcon"/>
                    {{ $t('config.tables.edit_tables') }}
                </b-button>
            </b-col>
        </b-row>
        <Table class="mt-1" :columnDefs="columnDefs" :rowData="businessUnitRoomTables" @deleteRow="remove" @edit="edit"/>

        <EditTables ref="editModal" :businessUnitRoomId="businessUnitRoomId" v-on:itemEdited="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import EditTables from '@/views/WorkPremises/EditTables'
    import {BButton, BCol, BOverlay, BRow} from 'bootstrap-vue'
    import RoomActions from '@/views/WorkPremises/ViewRoomActions'
    import * as Sentry from '@sentry/vue'

    export default {
        /*eslint-disable-next-line*/
        components: {Table, EditTables, BRow, BCol, BOverlay, BButton, RoomActions},
        props: {
            businessUnitRoomId: {
                type: String
            }
        },
        data() {
            return {
                businessUnitRoomTables: [],
                tables: [{id: 1, name: this.$t('config.tables.rectangle')}, {id: 2, name: this.$t('config.tables.circle')}],
                showLoader: false
            }
        },
        computed: {
            columnDefs() {
                const hasPermission = this.$hasPermission(this.$permissions.ConfigurationWrite)

                return [
                    {headerName: this.$t('table_fields.name'), field: 'name', filter: true, editable: hasPermission},
                    {
                        headerName: this.$t('table_fields.shape_type'),
                        field: 'shape_type',
                        filter: true,
                        floatingFilterComponentParams: () => {
                            return {type: 'select', values: this.tables}
                        },
                        cellEditorFramework: 'selectEditor',
                        cellEditorParams: () => {
                            return {values: this.tables}
                        },
                        cellRenderer: (params) => this.getShapeType(params.value),
                        editable: hasPermission
                    },
                    {headerName: this.$t('table_fields.hidden_table'),
                     field: 'hidden',
                     filter: true,
                     floatingFilterComponentParams:  {type: 'select', values: [{name: this.$t('general.yes'), value: true}, {name: this.$t('general.no'), value: false}]
                     },
                     selectValue: 'value',
                     cellEditorFramework: 'activeEditor',
                     cellRenderer: 'DisplayActive'
                    },
                    {
                        headerName: this.$t('table_fields.actions'),
                        editable: false,
                        filter: false,
                        sortable: false,
                        colId: 'actions',
                        cellRenderer: 'RoomActions',
                        cellRendererParams: {button: 'AddToRoom'}
                    }
                ]
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get(`/api/client/v1/work_premises/${this.businessUnitRoomId}/tables`)
                    this.businessUnitRoomTables = response.data ?? []
                    this.$emit('itemEdited', 0)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit() {
                try {
                    this.showLoader = true

                    await this.$http.put(`/api/client/v1/work_premises/${this.businessUnitRoomId}/tables`, this.businessUnitRoomTables)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            async remove(id) {
                let index = null

                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return

                    for (const table of this.businessUnitRoomTables) {
                        if (table.id === id) {
                            index = this.businessUnitRoomTables.indexOf(table)
                        }
                    }
                    this.businessUnitRoomTables.splice(index, 1)


                    await this.$http.put(`/api/client/v1/work_premises/${this.businessUnitRoomId}/tables`, this.businessUnitRoomTables)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getShapeType(value) {
                if (value === 1) return this.$t('config.tables.rectangle')
                else if (value === 2) return this.$t('config.tables.circle')
                else return '/'
            }
        },
        created() {
            this.$root.$on('addTable', (data) => this.$emit('addTableToRoom', data))
        },
        mounted() {
            this.$refs.Tables = this
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
